export const NOTI_MESS_TYPE = {
  MARKETING: 0,
  CREATE_ACCOUNT: 1,
  HAPPY_BIRTHDAY: 2,
  JOIN_SERVICE_SUCCESS: 3,
  JOIN_GROUP: 4,
  TARGET_START_DAY: 5,
  TARGET_END_DAY: 6,
  TARGET_START_WEEK: 7,
  TARGET_END_WEEK: 8,
  COACH_APPT_30_MINUTE: 9,
  COACH_APPT_1_DAY: 10,
  ACTIVITY: 11,
  SURVEY: 12,
};

export const NOTI_STATUS_TYPE = {
  DRAFT: 0,
  ACTIVE: 1,
  SENT: 2,
  RECALL: 3,
  INACTIVE: 4,
};

export const SEND_NOTI_TIME = {
  NOW: 1,
  SCHEDULE: 2,
};

export const communicationMixin = {
  data() {
    return {
      column: [
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'fullName',
          label: 'Bệnh nhân',
          sortable: false,
        },

        {
          key: 'age',
          label: 'Độ tuổi',
          sortable: false,
        },
        {
          key: 'gender',
          label: 'Giới tính',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Thời gian tạo',
          sortable: false,
        },
        {
          key: 'country',
          label: 'Quốc gia',
          sortable: false,
        },
        {
          key: 'province',
          label: 'Tỉnh Thành',
          sortable: false,
        },
        {
          key: 'district',
          label: 'Quận',
          sortable: false,
        },
        {
          key: 'wards',
          label: 'Huyện',
          sortable: false,
        },
        {
          key: 'isActive',
          label: 'Trạng Thái',
          sortable: false,
        },
      ],

      statusOpts: [
        { name: 'Draft', value: 0 },
        { name: 'Active', value: 1 },
        { name: 'Sent', value: 2 },
        { name: 'Recall', value: 3 },
      ],

      redirectOpts: [
        { name: 'Web Page Redirect', value: 1 },
        { name: 'App  Redirect', value: 2 },
      ],

      timeOpts: [
        { name: 'Gởi ngay lập tức', value: SEND_NOTI_TIME.NOW },
        { name: 'Gởi theo thời gian', value: SEND_NOTI_TIME.SCHEDULE },
      ],

      notificationObjectOpts: [
        { name: 'Gởi thông báo cho toàn bộ bệnh nhân', value: 1 },
        { name: 'Gởi thông báo cho từng bệnh nhân', value: 2 },
        // {
        //   name: 'Gởi thông báo cho các bệnh nhân thuộc các nhóm Health Coach',
        //   value: 3,
        // },
        { name: 'Gởi thông báo cho các bệnh nhân theo điều kiện ', value: 4 },
      ],

      isGenderOpts: [
        { id: 1, name: 'Nam' },
        { id: 2, name: 'Nữ' },
      ],

      diabetesStateOptions: [
        { name: 'Tiền tiểu đường', id: 1 },
        { name: 'Loại 1', id: 2 },
        { name: 'Loại 2', id: 3 },
      ],
      bmiOpts: Array.from({ length: 101 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
      bmiFromOpts: Array.from({ length: 101 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
      bmiToOpts: Array.from({ length: 101 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
    };
  },

  computed: {
    messageTypeOpts() {
      if (this.isEdit) {
        return [
          { name: 'Marketing', value: NOTI_MESS_TYPE.MARKETING },
          { name: 'Tạo tài khoản', value: NOTI_MESS_TYPE.CREATE_ACCOUNT },
          { name: 'Chúc mừng sinh nhật', value: NOTI_MESS_TYPE.HAPPY_BIRTHDAY },
          {
            name: 'Tham gia gói dịch vụ thành công',
            value: NOTI_MESS_TYPE.JOIN_SERVICE_SUCCESS,
          },
          { name: 'Phân vào nhóm', value: NOTI_MESS_TYPE.JOIN_GROUP },
          {
            name: 'Mục tiêu ngày - đầu ngày',
            value: NOTI_MESS_TYPE.TARGET_START_DAY,
          },
          {
            name: 'Mục tiêu ngày - cuối ngày',
            value: NOTI_MESS_TYPE.TARGET_END_DAY,
          },
          {
            name: 'Mục tiêu tuần - đầu tuần',
            value: NOTI_MESS_TYPE.TARGET_START_WEEK,
          },
          {
            name: 'Mục tiêu tuần - cuối tuần',
            value: NOTI_MESS_TYPE.TARGET_END_WEEK,
          },
          {
            name: 'Lịch hẹn huấn luyện viên - 30 phút',
            value: NOTI_MESS_TYPE.COACH_APPT_30_MINUTE,
          },
          {
            name: 'Lịch hẹn huấn luyện viên - 1 ngày',
            value: NOTI_MESS_TYPE.COACH_APPT_1_DAY,
          },
          { name: 'Activity', value: NOTI_MESS_TYPE.ACTIVITY },
          { name: 'Khảo sát', value: NOTI_MESS_TYPE.SURVEY },
        ];
      }
      return [
        { name: 'Marketing', value: NOTI_MESS_TYPE.MARKETING },
        { name: 'Activity', value: NOTI_MESS_TYPE.ACTIVITY },
        { name: 'Khảo sát', value: NOTI_MESS_TYPE.SURVEY },
      ];
    },
  },

  methods: {
    getCheckUserParams(payload, paging) {
      const filter = {
        notificationPushTarget: payload.notificationPushTarget,
        ageStart: payload.ageStart,
        ageEnd: payload.ageEnd,
        gender: payload.gender?.id,
        diabetesStatus: payload.diabetesStatus?.id,
        provinceId: payload.provinceId?.id,
        districtId: payload.districtId?.id,
        wardId: payload.wardId?.id,
        bmiStart: payload.bmi.from ? Number(payload.bmi.from.value) : null,
        bmiEnd: payload.bmi.to ? Number(payload.bmi.to.value) : null,
        page: paging.page,
        size: paging.pageSize,
      };
      const params = new URLSearchParams();
      for (const property in filter) {
        if (filter[property]) {
          params.append(property, filter[property]);
        }
      }
      if (payload.coaches?.length) {
        payload.coaches.forEach((el) => {
          params.append('trainingGroupIds', el.id);
        });
      }
      if (payload.clinics?.length) {
        payload.clinics.forEach((el) => {
          params.append('hospitalIds', el.id);
        });
      }
      if (payload.interestTopics?.length) {
        payload.interestTopics.forEach((el) => {
          params.append('interestTopicIds', el.id);
        });
      }
      return params;
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },

    notBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    notBeforeTimeToday(date) {
      if (this.isAutomaticNotify) return;
      let today = this.$moment(this.form.scheduleDate).format('DD/MM/YYYY');
      let newDate = this.$moment(new Date()).format('DD/MM/YYYY');
      let dateSelector = this.$moment(date).format('DD/MM/YYYY HH:mm');

      if (today == newDate) {
        return (
          dateSelector <
          this.$moment().add(15, 'minutes').format('DD/MM/YYYY HH:mm')
        );
      }
    },

    toastCheckUser(total) {
      this.$swal({
        icon: 'success',
        title: `Có ${total} người dùng thoả điều kiện thông báo `,
        timer: 3000,
      });
    },

    toastFailedMessage(mes) {
      this.$toastr.e({
        title: 'Lỗi!',
        msg: mes,
      });
    },
  },
};
